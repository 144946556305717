

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import {
  Utils,
  ALL_DATA_ADJUSTMENT_PAGINATION, EnumBalanceID,
} from '@/app/infrastructures/misc'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller, {
  RequestTopupListParams,
} from '@/app/ui/controllers/RequestTopupSaldoController'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import { AdjustmentStatus } from '@/app/infrastructures/misc/Constants/adjustment'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    LoadingOverlay,
    EmptyState,
    Badge,
  },
})
export default class allDataAdjustment extends Vue {
  controller = controller
  sortOptions = [
    { label: 'Terbaru', value: 'NEWEST' },
    { label: 'Terlama', value: 'OLDEST' },
  ]
  filterOptions = [
    { label: 'Semua', value: 'SHOWALL' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Requested', value: 'REQUESTED' },
  ]
  headers = [
    {
      title: 'Customer ID',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
    {
      title: 'Phone Number',
      customStyle: { maxWidth: '130px', minWidth: '130px' },
    },
    {
      title: 'Total Saldo',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
    {
      title: 'Request Date',
      customStyle: { maxWidth: '130px', minWidth: '130px' },
    },
    {
      title: 'Requestor',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
    {
      title: 'Aprroval',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
    {
      title: 'Status',
      customStyle: { maxWidth: '140px', minWidth: '140px' },
    },
    {
      title: 'Action',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
  ]
  parameters = {
    sortBy: this.sortOptions[0],
    filterStatus: this.filterOptions[0],
    page: 1,
    perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
    phoneNumber: '',
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterStatus:
          this.filterOptions.find(
            item => item.value === queries.filterStatus
          ) || this.filterOptions[0],
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
        phoneNumber: decodeURIComponent(queries.phoneNumber || ''),
      }
    }

    this.fetchAllDataAdjustment(true)
  }

  get params(): RequestTopupListParams {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterStatus: this.parameters.filterStatus.value,
      phoneNumber: encodeURIComponent(this.parameters.phoneNumber),
    }
  }

  private fetchAllDataAdjustment(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    this.controller.getHistoryRequestTop(this.params)
  }

  setNegativeOrMinus(val: string | undefined): string {
    return val === EnumBalanceID.DEDUCT ? '-' : ''
  }

  get adjustmentAllData(): Array<DataObject[]> {
    return this.controller.listRequestTopupHistory.map(item => {
      return [
        {
          value: item.customerId || '',
          customStyle: { maxWidth: '120px', minWidth: '120px' },
        },
        {
          value: item.phoneNumber ? Utils.countryIndonesiaPhoneNumber(item.phoneNumber, true) : '-',
          customStyle: { maxWidth: '130px', minWidth: '130px' },
        },
        {
          value: `${this.setNegativeOrMinus(item.balanceTypeID)}${Utils.toRupiah(Math.abs(item.totalSaldoRequest || 0))}`,
          customStyle: { maxWidth: '120px', minWidth: '120px' },
        },
        {
          value: item.createdAt ? Utils.formatTimeZone(
            Utils.formatDate(item.createdAt, 'DD MMM YYYY[\n]HH:mm Z')
          ) : '-',
          customStyle: { maxWidth: '130px', minWidth: '130px' },
        },
        {
          value: item.requestor || '',
          customStyle: { maxWidth: '120px', minWidth: '120px' },
        },
        {
          value: item.approval || '',
          customStyle: { maxWidth: '120px', minWidth: '120px' },
        },
        {
          value: Utils.toCapitalize(String(item.balanceStatusId)),
          customStyle: { maxWidth: '140px', minWidth: '140px' },
        },
        {
          value: `${item.customerId}-${item.balanceId}`,
          customStyle: { maxWidth: '120px', minWidth: '120px' },
        },
      ]
    })
  }

  private getType(status: string): string {
    if (status.toUpperCase() === AdjustmentStatus.REQUESTED) {
      return 'warning'
    } else if (status.toUpperCase() === AdjustmentStatus.APPROVED) {
      return 'success'
    } else if (status.toUpperCase() === AdjustmentStatus.REJECTED) {
      return 'error'
    }
    return 'warning'
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router
      .replace({
        query: val,
      })
      .catch(() => false)
  }
}
